import * as THREE from "three";
import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { frontTexture, backTexture } from "./texture";
import { OrbitControls } from "@react-three/drei";

function Box(props: I3DTextureProps) {
  const mesh = useRef<THREE.Mesh>(null!);
  const [mapFront, setMapFront] = useState<THREE.Texture>(new THREE.Texture());
  const [mapBack, setMapBack] = useState<THREE.Texture>(new THREE.Texture());

  useFrame((state, delta) => (mesh.current.rotation.y += 0.001));

  useEffect(() => {
    frontTexture(props).then((canvas) => {
      const loader = new THREE.TextureLoader();
      setMapFront(loader.load(canvas));
    });
    backTexture(props).then((canvas) => {
      const loader = new THREE.TextureLoader();
      setMapBack(loader.load(canvas));
    });
  }, [props]);

  const loader = new THREE.TextureLoader();
  const paper = loader.load("assets/paper.jpg");

  return (
    <mesh position={[0, 0, 0]} ref={mesh}>
      <boxGeometry args={[4, 6, 1]} />
      <meshStandardMaterial attach="material-0" map={paper}  />
      <meshStandardMaterial attach="material-1" map={paper} />
      <meshStandardMaterial attach="material-2" map={paper} />
      <meshStandardMaterial attach="material-3" map={paper} />
      <meshStandardMaterial attach="material-4" map={mapFront} />
      <meshStandardMaterial attach="material-5" map={mapBack} />
    </mesh>
  );
}


interface I3DTextureProps {
  dogName: string;
  dogType: Dog;
  seedType: string;
}

export interface IFrontTextureProps {
  dogName: string;
  dogType: Dog;
}

export interface IBackTextureProps {
  dogName: string;
  seedType: string;
}



export const Three: React.FC<I3DTextureProps> = (props) => {
  return (
    <Canvas style={{ background: "#272727", height: "500px", width:"100%" }}>
      <color attach="background" args={["transparent"]} />
      <ambientLight intensity={0.7} />
      <Box dogName={props.dogName} dogType={props.dogType} seedType={props.seedType} />
      <OrbitControls screenSpacePanning />
    </Canvas>
  );
};

export const TextureFront: React.FC<IFrontTextureProps> = (props) => {
  const [texture, setTexture] = useState("");

  useEffect(() => {
    frontTexture(props).then((canvas) => {
      setTexture(canvas);
    });
  }, [props]);

  return (
    <p>
      <img src={texture} />
    </p>
  );
};

export const TextureBack: React.FC<IBackTextureProps> = (props) => {
  const [texture, setTexture] = useState("");

  useEffect(() => {
    backTexture(props).then((canvas) => {
      setTexture(canvas);
    });
  }, [props]);

  return (
    <p>
      <img src={texture} />
    </p>
  );
};

export default Three;