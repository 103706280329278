import { IFrontTextureProps, IBackTextureProps } from "./Three";

interface ICanvasProps {
  width: number;
  height: number;
}

const drawLogo = async (
  context: CanvasRenderingContext2D,
  canvas: ICanvasProps
) => {
  await getPicture("logo.png").then((img) => {
    context.fillStyle = "#FFFFFF";
    context.moveTo(20, canvas.height / 2);
    context.lineTo(50, canvas.height / 2 - 50);
    context.lineTo(canvas.width - 50, canvas.height / 2 - 50);
    context.lineTo(canvas.width - 20, canvas.height / 2);
    context.lineTo(canvas.width - 50, canvas.height / 2 + 50);
    context.lineTo(50, canvas.height / 2 + 50);
    context.fill();
    let imgWidth = canvas.width - 200;
    let imgHeight = ((canvas.width - 200) * img.height) / img.width;
    context.drawImage(
      img,
      100,
      canvas.height / 2 - imgHeight / 2,
      imgWidth,
      imgHeight
    );
  });
};

const getPicture = (src: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const drawbackground = async (
  context: CanvasRenderingContext2D,
  canvas: ICanvasProps
) => {
  await getPicture("assets/paper.jpg").then((img) => {
    var ptrn = context.createPattern(img, "repeat");
    if (ptrn) context.fillStyle = ptrn;
    context.fillRect(0, 0, canvas.width, canvas.height);
  });
};

const drawName = (
  context: CanvasRenderingContext2D,
  canvas: ICanvasProps,
  name: string
) => {
  context.font = "65px 'Courgette', cursive";
  context.textAlign = "center";
  context.fillStyle = "white";
  context.fillText(name + "s", canvas.width / 2, 100, canvas.width);
  context.fillText("Leckerlies", canvas.width / 2, 180, canvas.width);
};

const drawOnlyBest = (
  context: CanvasRenderingContext2D,
  canvas: ICanvasProps,
  name: string
) => {
  context.font = "50px 'Courgette', cursive";
  context.textAlign = "center";
  context.fillStyle = "white";
  context.fillText("Nur das beste", canvas.width / 2, 100, canvas.width);
  context.fillText("für " + name, canvas.width / 2, 180, canvas.width);
};

const drawDog = async (
  context: CanvasRenderingContext2D,
  canvas: ICanvasProps,
  type: Dog
) => {
  await getPicture(`assets/Dogs/${type}.png`).then((img) => {
    let imgWidth = canvas.width - 150;
    let imgHeight = ((canvas.width - 150) * img.height) / img.width;
    context.drawImage(
      img,
      canvas.width / 2 - imgWidth / 2,
      canvas.height - imgHeight,
      imgWidth,
      imgHeight
    );
  });
};

const drawPlant = async (
  context: CanvasRenderingContext2D,
  canvas: ICanvasProps
) => {
  await getPicture("assets/plant.png").then((img) => {
    let imgWidth = canvas.width - 10;
    let imgHeight = ((canvas.width - 10) * img.height) / img.width;
    context.drawImage(
      img,
      canvas.width / 2 - imgWidth / 2,
      canvas.height - imgHeight - 10,
      imgWidth,
      imgHeight
    );
  });
};

const drawSeed = async (
  context: CanvasRenderingContext2D,
  canvas: ICanvasProps,
  seed: string
) => {
  await getPicture(`assets/Seeds/${seed}.png`)
    .then((img) => {
      let imgWidth = canvas.width - 150;
      let imgHeight = ((canvas.width - 150) * img.height) / img.width;

      context.drawImage(
        img,
        canvas.width / 2 - imgWidth / 2,
        canvas.height - imgHeight - 150,
        imgWidth,
        imgHeight
      );
    })
    .catch((e) => {});
};

export const frontTexture = async (
  pack: IFrontTextureProps
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    let canvas: ICanvasProps = { width: 400, height: 600 };

    const mycanvas = document.createElement("canvas");
    mycanvas.height = canvas.height;
    mycanvas.width = canvas.width;
    const context = mycanvas.getContext("2d");
    if (context) {
      const draw = async () => {
        await drawbackground(context, canvas);
        await drawLogo(context, canvas);
        drawName(context, canvas, pack.dogName);
        await drawDog(context, canvas, pack.dogType);
      };
      draw()
        .then(() => {
          resolve(mycanvas.toDataURL());
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};

export const backTexture = async (pack: IBackTextureProps): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    let canvas: ICanvasProps = { width: 400, height: 600 };

    const mycanvas = document.createElement("canvas");
    mycanvas.height = canvas.height;
    mycanvas.width = canvas.width;
    const context = mycanvas.getContext("2d");
    if (context) {
      const draw = async () => {
        await drawbackground(context, canvas);
        await drawPlant(context, canvas);
        drawOnlyBest(context, canvas, pack.dogName);
        await drawSeed(context, canvas, pack.seedType);
      };
      draw()
        .then(() => {
          resolve(mycanvas.toDataURL());
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};
