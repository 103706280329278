import React from 'react';
import Configurator from "./modules/Configurator";
import ResponsiveAppBar from './modules/ResponsiveAppBar';


function App() {
  return (
    <>
      <img src="logo.png" width={200}/>
       <ResponsiveAppBar />
       <Configurator />
    </>
  );
}

export default App;
