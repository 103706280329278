import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { TextureFront, TextureBack, Three } from "./Three";

const dogOptions: Dog[] = [
  "Spaniel",
  "Dachshund",
  "Schnauzer",
  "Husky",
  "Scottish Terrier",
  "Westie",
  "Retriever",
  "Basset Hound",
  "Rottweiler",
  "Poodle",
  "Collie",
  "Beagle",
  "Lab",
  "German Shepard",
  "Cocker Spaniel",
  "Boxer",
  "Bull Terrier",
  "Afghan",
  "Great Dane",
  "Pointer",
  "Greyhound",
  "Corgi",
  "Dobermann",
];

const flowers = [
  "Vogelauge",
  "Clarkia",
  "Sonnenhut",
  "Süßer Alyssum",
  "Leimkraut",
  "Löwenmäulchen",
  "Kamille",
];
const herbs = ["Basilikum", "Petersilie", "Dill", "Thymian", "Schnittlauch"];
const vegetables = [
  "Royal Chantenay Karotte",
  "Roma-Tomate",
  "Buttercrunch-Salat",
];

const Dropdown: React.FC<{
  value: string;
  onChange: (value: string) => void;
  label: string;
  options: string[];
}> = ({ value, onChange, label, options }) => (
  <FormControl fullWidth size="small" margin="dense" variant="standard">
    <InputLabel>{label}</InputLabel>
    <Select
      value={value}
      label={label}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option} divider sx={{ mb: 0 }}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const Package = () => {
  const [seedCategory, setSeedCategory] = useState("Blumen");
  const [seedType, setSeedType] = useState("");
  const [dogName, setDogName] = useState("Charlie");
  const [dogType, setDogType] = useState<Dog>("Spaniel");

  const [currentTab, setCurrentTab] = useState("3d");

  let seedOptions: string[] = [];
  switch (seedCategory) {
    case "Blumen":
      seedOptions = flowers;
      break;
    case "Kräuter":
      seedOptions = herbs;
      break;
    case "Gemüse":
      seedOptions = vegetables;
      break;
  }

  const seedVal = seedOptions.includes(seedType) ? seedType : seedOptions[0];

  return (
    <>
      <Divider sx={{ mb: 2, mt: 2 }}>Verpackung</Divider>
      <Stack direction={{xs: "column-reverse", lg: "row" }} spacing={3}>
        <Box component="div" sx={{ flex: 1 }}>
          <Box component="div" sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={currentTab} onChange={(_, val) => setCurrentTab(val)}>
              <Tab label="Vorne" value="front" />
              <Tab label="Hinten" value="back" />
              <Tab label="3D" value="3d" />
            </Tabs>
          </Box>
          {currentTab === "front" && (
            <TextureFront dogName={dogName} dogType={dogType} />
          )}
          {currentTab === "back" && (
            <TextureBack dogName={dogName} seedType={seedVal} />
          )}
          {currentTab === "3d" && (
            <Three dogName={dogName} dogType={dogType} seedType={seedVal} />
          )}
        </Box>
        <Box component="div" sx={{ flex: 1 }}>
          <Typography>
            Unsere Verpackungen sind einpflanzbar! Einfach die Verpackung in die
            Erde einpflanzen, und schon wachsen daraus neue Pflanzen. Welche
            Samen in die Verpackung eingearbeitet werden sollen, können Sie frei
            entscheiden.
          </Typography>

          <TextField
            label="Name des Hundes"
            value={dogName}
            onChange={(e) => setDogName(e.target.value)}
            fullWidth
            size="small"
            margin="dense"
            variant="standard"
          />
          <Dropdown
            value={dogType}
            onChange={(dog) => setDogType(dog as Dog)}
            label="Rasse"
            options={dogOptions}
          />
          <Dropdown
            value={seedCategory}
            onChange={setSeedCategory}
            label="Samen-Kategorie"
            options={["Blumen", "Kräuter", "Gemüse"]}
          />
          <Dropdown
            value={seedVal}
            onChange={setSeedType}
            label="Samen"
            options={seedOptions}
          />

          <Button variant="contained">in den Warenkorb</Button>
        </Box>
      </Stack>
    </>
  );
};

export default Package;
