import { createTheme } from "@mui/material/styles";

const CDcolors = {
  palette: {
    primary: {
      main: "#83bf00",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#00ff00",
    },
  },
};

const theme = createTheme({
  ...CDcolors,
  typography: {
    h1: {
      fontFamily: "Rajdhani",
      fontSize: "2.4rem",
      fontWeight: 500,
      color: CDcolors.palette.primary.main,
      marginBottom: "10px",
      textAlign: "center",
    },
    h2: {
      fontFamily: "Rajdhani",
      fontSize: "1.7rem",
      fontWeight: 500,
      marginBottom: "3px",
      marginTop: "20px",
    },
    h3: {
      fontFamily: "Rajdhani",
      fontSize: "1.5rem",
      fontWeight: 500,
      marginBottom: "3px",
      marginTop: "20px",
    },
    body1: {
      marginBottom: "10px",
    },
  },
});

export default theme;
