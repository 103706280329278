import React, { useState } from "react";
import Product from "./Product";
import Package from "./Package";
import { Box, Link, Paper } from "@mui/material";

function Configurator() {
  return (
    <Paper sx={{ mt: 2, p: 1, mb: 7 }}>
      <Product />
      <Package />
      <Box component="footer"
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          height: "35px",
          width: "100%",
          background: "white",
          textAlign: "center",
          borderTop: "1px solid black",
          borderColor: "primary.main",
        }}
      >
        <Link href="https://www.sysdevio.at/about" sx={{ lineHeight: "35px" }}>
          Impressum
        </Link>
      </Box>
    </Paper>
  );
}

export default Configurator;
