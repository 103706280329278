import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

const pages = ["Konfigurator", "Produkte", "Preise", "Blog"];

const ResponsiveAppBar = () => {
  return (
    <AppBar position="static">
      <Toolbar >
          {pages.map((page) => (
            <Button key={page} sx={{ color: "white" }}>
              {page}
            </Button>
          ))}
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
