import React, { useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";

const AMOUNT = [
  {
    weight: "100g",
    price: "9,99€",
  },
  {
    weight: "250g",
    price: "19,99€",
  },
  {
    weight: "1kg",
    price: "49,99€",
  },
];

const Product = () => {
  const [amount, setAmount] = useState(0);
  return (
    <Stack direction={{xs: "column", sm: "row" }} spacing={2}>
      <Box component="div">
        <img src="assets/keks.jpg" style={{width: "100%"}}/>
      </Box>
      <Box component="div">
        <Typography variant="h1">Hundekekse mit Karotte</Typography>
        <Typography variant="h2" mb={0}>
          {AMOUNT[amount].price}
        </Typography>
        <Typography variant="h3" m={0} fontSize={18}>
          ({AMOUNT[amount].weight})
        </Typography>
        <Typography>
          Knusprige, schmackhafte Hundekekse mit Karotte von Keksdieb
          Hundeleckerlis. Gut verträgliche, 100 % natürliche Zutaten höchster
          Qualität.
        </Typography>
        <Stack direction="row" spacing={1}>
          {AMOUNT.map((item, idx) => {
            return (
              <Button
                key={idx}
                onClick={() => setAmount(idx)}
                variant={idx === amount ? "contained" : "outlined"}
              >
                {item.weight}
              </Button>
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};

export default Product;
